// SearchBar.js
import React, { useState } from "react";

const SearchBar = ({ onSearch }) => {
  const [query, setQuery] = useState("");

  const handleInputChange = (e) => {
    const value = e.target.value;
    setQuery(value);
    onSearch(value);
  };

  return (
    <div>
      <input
        type="text"
        placeholder="Recherche..."
        value={query}
        onChange={handleInputChange}
        className="searchBar"
      />
    </div>
  );
};

export default SearchBar;
