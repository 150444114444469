/** @format */

import React, {useState} from "react";

const OptionsProjet = ({ selectedOption, handleOptionClick }) => {

  const [selectedButton, setSelectedButton] = useState(null);


  const getButtonContent = (option) => {
    switch (option) {
      case "create_website":
        return "Créer un site web";
      case "improve_website":
        return "Améliorer votre site web";
      case "online_audit_coaching":
        return "Audit et coaching en ligne";
      case "work_on_online_presence":
        return "Travailler votre présence en ligne";
      case "create_app":
        return "Créer une application";
      case "discuss_project":
        return "Discuter de votre projet";
      case "develop_digital_marketing_strategy":
        return "Développer une stratégie de marketing digital";
      case "optimize_seo":
        return "Optimiser votre référencement SEO";
      case "integrate_e-commerce_features":
        return "Intégrer des fonctionnalités e-commerce";
      case "design_impactful_visual_identity":
        return "Concevoir une identité visuelle percutante";
      default:
        return "";
    }
  };

  const handleClick = (option) => {
    setSelectedButton(option); // Actualiza el botón seleccionado
    return handleOptionClick(getButtonContent(option), option); // Llama a la función de clic del botón padre y devuelve el contenido del botón
  };

  return (
    <>
      <h2>Choisissez votre option :</h2>
      <p>
        Prêts à passer aux choses sérieuses ? Parlons maintenant de la nature de
        votre projet :
      </p>
      <div className="choice-box">

        {["improve_website", "online_audit_coaching", "create_app", "create_website", "design_impactful_visual_identity", "develop_digital_marketing_strategy", "discuss_project", "integrate_e-commerce_features", "optimize_seo", "work_on_online_presence"  ].map((option) => (
            <button
              key={option}
              className={selectedButton === option ? "active-option" : ""}
              onClick={() => handleClick(option)}
            >
              {getButtonContent(option)}
            </button>
          ))}



{/*
        <button
          className={
            selectedOption === "improve_website" ? "active-option" : ""
          }
          onClick={() => handleOptionClick(getButtonContent("improve_website"))}
        >
          {getButtonContent("improve_website")}
        </button>
        <button
          className={
            selectedOption === "online_audit_coaching" ? "active-option" : ""
          }
          onClick={() =>
            handleOptionClick(getButtonContent("online_audit_coaching"))
          }
        >
          {getButtonContent("online_audit_coaching")}
        </button>
        <button
          className={selectedOption === "create_app" ? "active-option" : ""}
          onClick={() => handleOptionClick(getButtonContent("create_app"))}
        >
          {getButtonContent("create_app")}
        </button>
        <button
          className={selectedOption === "create_website" ? "active-option" : ""}
          onClick={() => handleOptionClick(getButtonContent("create_website"))}
        >
          {getButtonContent("create_website")}
        </button>
        <button
          className={
            selectedOption === "design_impactful_visual_identity"
              ? "active-option"
              : ""
          }
          onClick={() =>
            handleOptionClick(
              getButtonContent("design_impactful_visual_identity")
            )
          }
        >
          {getButtonContent("design_impactful_visual_identity")}
        </button>
        <button
          className={
            selectedOption === "develop_digital_marketing_strategy"
              ? "active-option"
              : ""
          }
          onClick={() =>
            handleOptionClick(
              getButtonContent("develop_digital_marketing_strategy")
            )
          }
        >
          {getButtonContent("develop_digital_marketing_strategy")}
        </button>
        <button
          className={
            selectedOption === "discuss_project" ? "active-option" : ""
          }
          onClick={() => handleOptionClick(getButtonContent("discuss_project"))}
        >
          {getButtonContent("discuss_project")}
        </button>
        <button
          className={
            selectedOption === "integrate_e-commerce_features"
              ? "active-option"
              : ""
          }
          onClick={() =>
            handleOptionClick(getButtonContent("integrate_e-commerce_features"))
          }
        >
          {getButtonContent("integrate_e-commerce_features")}
        </button>
        <button
          className={selectedOption === "optimize_seo" ? "active-option" : ""}
          onClick={() => handleOptionClick(getButtonContent("optimize_seo"))}
        >
          {getButtonContent("optimize_seo")}
        </button>
        <button
          className={
            selectedOption === "work_on_online_presence" ? "active-option" : ""
          }
          onClick={() =>
            handleOptionClick(getButtonContent("work_on_online_presence"))
          }
        >
          {getButtonContent("work_on_online_presence")}
        </button>
        */}
      </div>
        
      {/* {selectedOption && (
        <div>
          <p>
            Vous avez choisi <strong>{getButtonContent(selectedOption)}</strong>
            .
          </p>
        </div>
      )} */}
    </>
  );
};

export default OptionsProjet;
