/** @format */

import React from "react";
import { NavLink } from "react-router-dom";


export const LogoMenu = () => {
 
  return (
    <NavLink to="/">
      <div className="logo">
      <img src="../img/Divs_logo.png" />
      </div>
    </NavLink>
  );
};
