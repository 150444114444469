/** @format */

import React, { useEffect, useState } from "react";
import { SlideImages } from "../plugins/SlideImages";
import TechnologiesSection from "../plugins/Techno";
import proyectosData from "../services.json";
import { Link } from "react-router-dom";
import { Footer } from "../components/BarMenu/Footer";
import { SeoComponent } from "../components/SeoComponent";

export const Realisations = () => {
  // ------------------------------------------------
  // ------------------------------------------------
  const [proyectos, setProyectos] = useState([]);
  useEffect(() => {
    setProyectos(proyectosData);
  }, []);
  // ------------------------------------------------
  // ------------------------------------------------
  // Créez une nouvelle instance de Date avec la date souhaitée
  const createDate = new Date("2024-03-11");
  // Obtenez la date au format ISOString pour l'utiliser dans votre JSON-LD
  const createAt = createDate.toISOString();
  return (
    <div className="realisations-container">
      <SeoComponent
        titlePage="Nos projets chez Divs Labs"
        descPage="Trouvez des projets réalisés en React, Wordpress, Elementor, SEO, de A à Z chez Divs Labs."
        keyPage={[
          "Création de sites web sur mesure",
          "Refonte de sites web",
          "Développement web",
          "Coaching en programmation",
          "Développement Symfony",
          "Développement React",
          "Développement frontend",
          "Développement backend",
          "PHP",
          "JavaScript",
          "HTML",
          "CSS",
          "Applications réactives",
          "Coaching Symfony",
          "Coaching React",
          "Portfolio web",
          "Développeur freelance",
          "Services web",
          "Expérience utilisateur (UX/UI)",
          "Responsive design",
          "SEO (Optimisation pour les moteurs de recherche)",
          "Mentorat en programmation",
          "Technologies web",
          "Conception de site internet",
          "Programmation web avancée",
        ]}
        ogTitle="Découvrez nos projets chez Divs Labs"
        ogDesc="Trouvez des projets réalisés en React, Wordpress, Elementor, SEO, de A à Z chez Divs Labs."
        ogUrl="https://divs.fr/realisations/"
        ogImg="../img/realisations/tresinteriors/logo.png"
        tCard="../img/realisations/tresinteriors/logo.png"
        tTitle="Découvrez nos projets chez Divs Labs"
        tDesc="Trouvez des projets réalisés en React, Wordpress, Elementor, SEO, de A à Z chez Divs Labs."
        tImg="../img/realisations/tresinteriors/logo.png"
        rev="1.0"
        typePage="ItemList"
        createAt={createAt}
        imagePage="https://divs.fr/img/machine.png"
        urlBase="https://divs.fr/"
      />
      <div className="banner-portfolio">
        <h1>Nos projets</h1>
        <p>
          Nous avons conçu cette section, avec soin pour vous montrer nos
          realisations les plus belles
        </p>
      </div>
      <div className="projets">
        {proyectos.map((proyecto) => (
          <div className="box-projet" key={proyecto.id}>
            <h2>{proyecto.entreprise}</h2>
            <div>
              <p>{proyecto.desc}</p>
            </div>

            <div className="img-array">
              <SlideImages images={proyecto.array_h} />
            </div>

            <Link
              to={`/realisations/${proyecto.slug}`}
              key={proyecto.id}
              className="footer-button realisation-btn"
            >
              Découvrir le projet
            </Link>
            <TechnologiesSection technos={proyecto.technos} />
          </div>
        ))}
      </div>

      <Footer />
    </div>
  );
};
