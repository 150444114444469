/** @format */

import React, { useState } from "react";
import { SidebarBlog } from "../components/blog/SidebarBlog";
import { ListPosts } from "../components/blog/ListPosts";

export const Blog = () => {
  const [searchTerm, setSearchTerm] = useState("");

  const handleSearch = (query) => {
    setSearchTerm(query);
  };
  return (
    <div className="content-blog">
      <div className="blog">
        <h1>Bienvenue sur le Blog de Divs Labs</h1>
        <div className="card-post-container">
          <ListPosts searchTerm={searchTerm} />
        </div>
      </div>
      <SidebarBlog onSearch={handleSearch} />
    </div>
  );
};
