/** @format */

import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

export const ListPosts = ({ searchTerm }) => {
  const [data, setPosts] = useState([]); // Set initial state to an empty array
  const [loading, setLoading] = useState(true);
  const urlBase = process.env.REACT_APP_DIVS_BACKEND_URL;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${urlBase}articles/`, {
          mode: "cors", // Indica al navegador que esta es una solicitud CORS
        });

        console.log(data);

        // Log the entire API response
        console.log("API Response:", response);

        const responseData = response.data;
        const hydraMember = responseData["hydra:member"];
        const newData = Array.isArray(hydraMember) ? hydraMember : [];

        setPosts(newData);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setTimeout(() => {
          setLoading(false);
        }, 800);
      }
    };

    fetchData();
  }, [urlBase]);

  const filteredPosts = data.filter((post) => {
    const postTitle = post.title ? post.title.toLowerCase() : "";
    const searchTermLower = searchTerm ? searchTerm.toLowerCase() : "";
    return postTitle.includes(searchTermLower);
  });

  if (loading) {
    return (
      <div className="loading-container">
        <div className="loading-point point-1"></div>
        <div className="loading-point point-2"></div>
        <div className="loading-point point-3"></div>
      </div>
    );
  }

  return filteredPosts.map((post) => (
    <>
      <Link className="link-blog" to={`/blog/${post.slug}`} key={post.id}>
        <div>
          <Helmet>
            <title>Blog Divs</title>
          </Helmet>
        </div>
        <article className="b-card" key={post.id}>
          <div className="b-content-card">
            <h2>{post.title}</h2>
            {/* <p dangerouslySetInnerHTML={{ __html: post.content.slice(0, 200) }} /> */}
          </div>

          <div className="b-img-container">
            <img
              className="img-blog"
              src={urlBase + post.imagePostURL}
              alt="Innover"
            />
            <img
              className="img-blog2 query-hide"
              src={urlBase + post.imagePostURL}
              alt="Innover"
            />
          </div>
        </article>
      </Link>
      <Link className="link-blog" to={`/blog/${post.slug}`} key={post.id}>
        <div>
          <Helmet>
            <title>Blog Divs</title>
          </Helmet>
        </div>
        <article className="b-card" key={post.id}>
          <div className="b-content-card">
            <h2>{post.title}</h2>
            {/* <p dangerouslySetInnerHTML={{ __html: post.content.slice(0, 200) }} /> */}
          </div>

          <div className="b-img-container">
            <img
              className="img-blog"
              src={urlBase + post.imagePostURL}
              alt="Innover"
            />
            <img
              className="img-blog2 query-hide"
              src={urlBase + post.imagePostURL}
              alt="Innover"
            />
          </div>
        </article>
      </Link>
      <Link className="link-blog" to={`/blog/${post.slug}`} key={post.id}>
        <div>
          <Helmet>
            <title>Blog Divs</title>
          </Helmet>
        </div>
        <article className="b-card" key={post.id}>
          <div className="b-content-card">
            <h2>{post.title}</h2>
            {/* <p dangerouslySetInnerHTML={{ __html: post.content.slice(0, 200) }} /> */}
          </div>

          <div className="b-img-container">
            <img
              className="img-blog"
              src={urlBase + post.imagePostURL}
              alt="Innover"
            />
            <img
              className="img-blog2 query-hide"
              src={urlBase + post.imagePostURL}
              alt="Innover"
            />
          </div>
        </article>
      </Link>
    </>
  ));
};
