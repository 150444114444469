/** @format */

import React from "react";

const IphoneFrame = ({ imageSrc }) => {
  return (
    <div className="iphone-container">
      {" "}
      {/* Conteneur pour le cadre de l'iPhone */}
      <img
        src="../img/frame.png"
        alt="Cadre de l'iPhone"
        className="iphone-frame"
      />{" "}
      {/* Cadre de l'iPhone */}
      <img
        src={imageSrc}
        alt="Capture d'écran"
        className="iphone-screen"
      />{" "}
      {/* Ton image à l'intérieur du cadre */}
    </div>
  );
};

export default IphoneFrame;
