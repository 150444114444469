/** @format */

import React from "react";

const DescriptionProjet = ({
  formData,
  handleInputChange,
  handlePrevStep,
  handleSubmit,
  step,
  showNavigation,
}) => {
  const renderDescription = () => {
    switch (formData.choixService) {
      case "Créer un site web":
        return (
          <p>
            Nous avons des options en React, Elementor, WordPress, selon votre
            budget et vos besoins.
          </p>
        );
      case "Améliorer votre site web":
        return <p>Noooooo</p>;
      case "Audit et coaching en ligne":
        return <p>Noooooo</p>;
      case "Travailler votre présence en ligne":
        return (
          <p>
            Nous vous aiderons à optimiser votre présence en ligne pour
            atteindre vos objectifs.
          </p>
        );
      case "Créer une application":
        return (
          <p>
            Vous voulez passer à la vitesse supérieure ? Nous développerons une
            application personnalisée pour répondre à vos besoins spécifiques.
          </p>
        );
      case "Discuter de votre projet":
        return (
          <p>
            Votre projet est encore en herbe, mais ne vous inquiétez pas.
            Parlons un peu. Parlons de votre vision et de la manière dont nous
            pouvons vous aider à la concrétiser.
          </p>
        );
      case "Développer une stratégie de marketing digital":
        return (
          <p>
            Ne laissez rien au hasard, créons ensemble une stratégie infaillible
            pour stimuler vos ventes, attirer de nouveaux clients et propulser
            votre entreprise vers le sommet{" "}
          </p>
        );
      case "Optimiser votre référencement SEO":
        return (
          <p>
            Avec notre expertise, transformez votre stratégie SEO en un
            véritable jeu d'enfant et développez votre présence en ligne de
            manière exponentielle
          </p>
        );
      case "Intégrer des fonctionnalités e-commerce":
        return (
          <p>
            Prêt à faire décoller vos ventes en ligne ? Avec notre expertise,
            intégrez des fonctionnalités e-commerce de pointe et lancez-vous
            dans l'aventure de la vente en ligne avec confiance !
          </p>
        );
      case "Concevoir une identité visuelle percutante":
        return (
          <p>
            À la recherche d'une identité visuelle distinctive et percutante ?
            Nous sommes là pour créer une image qui reflète votre unicité,
            égalité et pureté, captivant ainsi l'essence même de votre marque.
          </p>
        );
      default:
        return <p>Description de l'option {formData.choixService}...</p>;
    }
  };
  return (
    <>
      <p>Nous serons ravis de travailler avec vous !</p>

      {formData.choixService && (
        <>
          <p>Vous avez chosi : {formData.choixService}...</p>

          {renderDescription()}

          <textarea
            name="descriptionProjet"
            value={formData.descriptionProjet}
            onChange={handleInputChange}
            placeholder="Expliquez-nous davantage votre projet..."
          />

          {showNavigation && (
            <div className="navigation-buttons">
              <button onClick={handlePrevStep} disabled={step === 0}>
                Précédent
              </button>

              <button onClick={handleSubmit}>Envoyer le formulaire</button>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default DescriptionProjet;
