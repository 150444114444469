/** @format */

import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

export const CardComponent = ({
  img,
  imgBack,
  alt,
  titre,
  description,
  titreBack,
  appelAction,
  actionButton,
  route,
}) => {
  const [isFront, setIsFront] = useState(false);

  // Hover
  const [isClicked, setIsClicked] = useState(false);
  const [hoverEnabled, setHoverEnabled] = useState(false);

  const cardFlip = () => {
    setIsFront(!isFront);
    setHoverEnabled(true);
    setTimeout(() => {
      setIsClicked(false);
    }, 1000); // Désactiver l'effet de clic après 1 seconde
  };

  useEffect(() => {
    let hoverTimer;
    if (hoverEnabled) {
      hoverTimer = setTimeout(() => {
        setHoverEnabled(false);
      }, 100); // Désactiver l'effet de survol après 1 seconde
    }

    return () => clearTimeout(hoverTimer);
  }, [hoverEnabled]);

  return (
    <div
      className={`card ${isClicked ? "clicked" : ""} ${
        hoverEnabled ? "hover-enabled" : ""
      } ${isFront ? "back-side" : ""}`}
      onClick={cardFlip}
    >
      {!isFront ? (
        <>
          <img src={img} alt={alt} />
          <div className="content-card">
            <h2>{titre}</h2>
            <p>{description}</p>
          </div>
        </>
      ) : (
        <>
          <img src={imgBack} alt={alt} />
          <div className="content-card back-side-content">
            <h3>{titreBack}</h3>
            <p>{appelAction}</p>
            <Link className="button-89" to={route}>
              {actionButton}
            </Link>
          </div>
        </>
      )}
    </div>
  );
};
