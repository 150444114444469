/** @format */

import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import "./styles/menu.css";
import "./styles/home.css";
import "./styles/about.css";
import "./styles/blog.css";
import "./styles/footer.css";
import "./styles/contact.css";
import "./styles/realisations.css";
import "./styles/slideImage.css";
import "./styles/QRcode.css";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<App />);


