/** @format */

import "./App.css";
// import CookieConsentBanner from "./plugins/Cockies";
import { RoutePrincipale } from "./routes/RoutePrincipale";

function App() {
  return (
    <>
      {/* <CookieConsentBanner /> */}
      <RoutePrincipale />
    </>
  );
}

export default App;
