/** @format */

import React from "react";
import { MenuBurger } from "../menu/MenuBurger";
import { LogoMenu } from "../menu/LogoMenu";

export const NavBar = () => {
  return (
    <>
      <div className="section-menu">
        <MenuBurger />
        <LogoMenu />
      </div>
    </>
  );
};
