/** @format */

import React from "react";
import { CardComponent } from "./CardComponent";

export const CardHome = () => {
  return (
    <>
      <h2 className="titre-services">
        Découvrez ce que nous pouvons faire ensemble :
      </h2>

      <div className="card-container">
        <CardComponent
          img="../img/cards/conception.png"
          imgBack="../img/cards/conceptionBack.png"
          alt="Conception Web Intuitive"
          titre="Conception Web Intuitive"
          description="Mettre l'accent sur la compréhension naturelle de votre identité permet de concevoir une interface qui transmet efficacement votre essence aux visiteurs."
          titreBack="Une philoshopie pour tous"
          appelAction="Développer des projets avec conscience pour une Terre plus solidaire."
          route="/a-propos"
          actionButton="Qui sommes nous ?"
        />
        <CardComponent
          img="../img/cards/reactif-front.png"
          imgBack="../img/cards/reactif.png"
          alt="Développement Réactif"
          titre="Développement Réactif"
          description="Nous adaptons la technologie en fonction de vos besoins, en utilisant aussi bien WordPress que React, pour vous offrir un site performant."
          titreBack="L'authenticité"
          appelAction="Dans chaque projet, chaque détail est soigneusement conçu avec sensibilité."
          route="/realisations"
          actionButton="Nos realisations"
        />
        <CardComponent
          img="../img/cards/experience.png"
          imgBack="../img/cards/exp-uti.png"
          alt="Expérience Utilisateur Mémorable"
          titre="Expérience Utilisateur"
          description="Créer des interactions positives avec les utilisateurs est la clé du succès, pour offrir une expérience mémorable"
          titreBack="Connaissances pour tous"
          appelAction="Ensemble, grandissons avec du contenu accessible à tous pour démarrer."
          route="/blog"
          actionButton="Notre Blog"
        />
        <CardComponent
          img="../img/cards/seo.png"
          imgBack="../img/cards/seo-back.png"
          alt="Optimisation SEO pour une Visibilité Maximale"
          titre="Optimisation SEO"
          description="Mettre en place une stratégie percutante pour maximiser votre visibilité sur les moteurs de recherche grâce au SEO, soyez remarquable"
          titreBack="Plus proche que jamais"
          appelAction="Rejoignez-nous pour explorer de nouveaux horizons"
          route="/contact"
          actionButton="Contactez-nous"
        />
      </div>
    </>
  );
};
