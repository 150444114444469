/** @format */

import React, { useState, useEffect } from "react";
import IphoneFrame from "../components/IphoneFrame";

export const SlideImages = ({ images, iphoneFrame }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleCircleClick = (index) => {
    setCurrentIndex(index);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 10000);

    return () => clearInterval(interval);
  }, [currentIndex, images.length]);

  return (
    <div className="slide-image-container">
      {iphoneFrame ? (
        <IphoneFrame
          imageSrc={process.env.PUBLIC_URL + `/img/${images[currentIndex]}`}
        />
      ) : (
        <img
          src={process.env.PUBLIC_URL + `/img/${images[currentIndex]}`}
          alt={`slide ${currentIndex}`}
        />
      )}
      <div className="circle-container">
        {images.map((_, index) => (
          <div
            key={index}
            className={`circle ${currentIndex === index ? "active" : ""}`}
            onClick={() => handleCircleClick(index)}
          ></div>
        ))}
      </div>
    </div>
  );
};
