/** @format */

import React from "react";

export const ScrollArrows = ({ direction, onClick, optionalClass }) => {
  return (
    <>
      <div className={`scroll-arrow ${direction} ${optionalClass}`} onClick={onClick}>
        {direction === "up" ? (
          <img src="../img/arrow-up.png" alt="Up" />
        ) : (
          <img src="../img/arrow-down.png" alt="Down" />
        )}
      </div>
    </>
  );
};
