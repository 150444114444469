/** @format */

import React from "react";

export const Services = () => {
  return (
    <div className="main-services">
      <div className="section">
        <div className="section-inside">
          <h2>Développement Web avec React</h2>
          <p>
            Nous utilisons
            <a
              href="https://reactjs.org/docs/getting-started.html"
              target="_blank"
              rel="noopener noreferrer"
            >
              React
            </a>
            , une bibliothèque JavaScript populaire, pour créer des interfaces
            utilisateur interactives et dynamiques. Les avantages de React
            incluent une meilleure performance, une gestion efficace de l'état,
            et une composabilité accrue pour un développement rapide et
            maintenable.
          </p>
        </div>
        <div className="container">
          <div>
            <img
              src="../img/services/react-logo.png"
              alt="React Logo"
              className="technology-image"
            />
          </div>
        </div>
      </div>

      <div className="section">
        <div className="section-inside">
          <h2>Développement d'Applications avec Symfony</h2>
          <p>
            Pour le côté serveur, nous utilisons{" "}
            <a
              href="https://symfony.com/doc/current/index.html"
              target="_blank"
              rel="noopener noreferrer"
            >
              Symfony
            </a>
            , un framework PHP robuste. Symfony offre une architecture
            modulaire, une gestion des dépendances efficace, et une communauté
            active, ce qui facilite le développement d'applications web
            sécurisées et évolutives.
          </p>
        </div>
        <div className="container">
          <div>
            <img
              src="../img/services/symfony-logo.png"
              alt="Symfony Logo"
              className="technology-image"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
