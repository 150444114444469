/** @format */

import React from "react";
import {
  FaLinkedin,
  FaGithub,
  FaWhatsapp,
  FaTelegram,
  FaDiscord,
  FaInstagram,
} from "react-icons/fa";
import { NavLink } from "react-router-dom";

export const SocialBar = ({
  showLinkedin,
  showGithub,
  showWhatsapp,
  showTelegram,
  showDiscord,
  showInstagram,
}) => {
  return (
    <>
      <div className="socialBar">
        {showLinkedin && (
          <NavLink target="_blank" to={"https://linkedin.com/in/divseidon"}>
            <FaLinkedin size={30} className="icons" />
          </NavLink>
        )}
        {showGithub && (
          <NavLink target="_blank" to={"https://github.com/Divseidon"}>
            <FaGithub size={30} className="icons" />
          </NavLink>
        )}
        {showInstagram && (
          <NavLink target="_blank" to={"https://www.instagram.com/divseidon/"}>
            <FaInstagram size={30} className="icons" />
          </NavLink>
        )}
        {showWhatsapp && (
          <NavLink
            target="_blank"
            to={"https://chat.whatsapp.com/LQ9yCDoCFUo3eYKv8KKt4H"}
          >
            <FaWhatsapp size={30} className="icons" />
          </NavLink>
        )}
        {showTelegram && (
          <NavLink target="_blank" to={"https://t.me/+z3Ky6KUu-6Y0YWNk"}>
            <FaTelegram size={30} className="icons" />
          </NavLink>
        )}
        {showDiscord && (
          <NavLink target="_blank" to={"https://discord.gg/UgevntNb"}>
            <FaDiscord size={30} className="icons" />
          </NavLink>
        )}
      </div>
    </>
  );
};

export default SocialBar;
