/** @format */

import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom"; // Asumiendo que estás usando React Router
import proyectosData from "../../services.json";
import ReactMarkdown from "react-markdown";

import { SlideImages } from "../../plugins/SlideImages";
import { Link } from "react-router-dom";

import { Footer } from "../BarMenu/Footer";
import { MarketingAffiliation } from "../MarketingAffiliation";
import { ContactMe } from "../ContactMe";
import { SeoComponent } from "../SeoComponent";

export const PostPortfolio = () => {
  const { slug } = useParams(); // Obtener el slug de la URL
  const [projectFinded, setProjectFinded] = useState(null);

  useEffect(() => {
    // Buscar el proyecto actual basándote en el slug
    const projectCurrent = proyectosData.find(
      (proyecto) => proyecto.slug === slug
    );

    if (projectCurrent) {
      setProjectFinded(projectCurrent);
    }
  }, [slug]);

  if (!projectFinded) {
    return (
      <div className="loading-container">
        <div className="loading-point point-1"></div>;
        <div className="loading-point point-2"></div>;
        <div className="loading-point point-3"></div>;
      </div>
    );
  }

  return (
    <>
      <SeoComponent
        titlePage={projectFinded.seo.titlePage}
        descPage={projectFinded.seo.descPage}
        keyPage={projectFinded.seo.keyPage}
        ogTitle={projectFinded.seo.ogTitle}
        ogDesc={projectFinded.seo.ogDesc}
        ogUrl={projectFinded.seo.ogUrl}
        ogImg={projectFinded.seo.ogImg}
        tCard={projectFinded.seo.tCard}
        tTitle={projectFinded.seo.tTitle}
        tDesc={projectFinded.seo.tDesc}
        tImg={projectFinded.seo.tImg}
        rev={projectFinded.seo.rev}
        typePage={projectFinded.seo.typePage}
        createAt={projectFinded.seo.createAt}
        imagePage={projectFinded.seo.imagePage}
        urlBase={projectFinded.seo.urlBase}
      />

      <div className="realisation-solo-container">
        <div className="realisation-solo-title">
          <img
            className="logo-entreprise"
            src={projectFinded.projet_logo}
            alt=""
          />

          <h1>{projectFinded.titre}</h1>
        </div>

        <div className="realisation-solo-header">
          <div className="solo-slides-pc">
            <SlideImages images={projectFinded.array_h} />
          </div>

          <div className="entreprise-desc-cont">
            <ReactMarkdown>{projectFinded.desc_projet}</ReactMarkdown>
          </div>
        </div>

        <div className="section-mobile">
          <h2>Aperçu de la version mobile</h2>

          <div className="content-solo-mobile">
            <div className="solo-slides-mobile">
              <SlideImages iphoneFrame images={projectFinded.array_v} />
            </div>
            
            <div className="entreprise-section">
            <ReactMarkdown>{projectFinded.desc_entreprise}</ReactMarkdown>
            <h3>Visitez leur site Web :</h3>
            <Link
            className="minimal-button"
            to={projectFinded.link_projet}
            target="_BLANK"
          >
            {projectFinded.entreprise}
          </Link>
          </div>
          

          </div>

        </div>

        {/* ------------------------------------------- */}
        <div className="images-entreprise">
          <img src={projectFinded.projet_logo} alt="" />
          <img src={projectFinded.array_h[0]} alt="" />
        </div>
        <ContactMe />
      </div>
      <div className="box-affiliation">
        {projectFinded.elementor && projectFinded.hostinger ? (
          <MarketingAffiliation elementor={true} hostinger={true} />
        ) : projectFinded.elementor ? (
          <MarketingAffiliation elementor={true} hostinger={false} />
        ) : projectFinded.hostinger ? (
          <MarketingAffiliation elementor={false} hostinger={true} />
        ) : projectFinded.qonto ? (
          <MarketingAffiliation qonto={true} />
        ) : (
          ""
        )}
      </div>

      <Footer />
    </>
  );
};
