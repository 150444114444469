/** @format */

import React from "react";
import { Link } from "react-router-dom";

const Elementor = () => {
  return (
    <div className="box-elementor">
      <div className="pv-3rem elementor-type">
        <Link
          target="_blank"
          className="techno-link"
          to="https://be.elementor.com/visit/?bta=222415&nci=5566"
        >
          <img src="../img/M-Affiliation/black_ele.png" alt={""} />
        </Link>

        <div>
          <h3>
            <span>
              Imaginez-vous en train de créer un site web aussi dynamique que
              celui-ci, avec la flexibilité et la puissance d'Elementor.
            </span>
            <br />
            <br />
            C'est possible, et ça pourrait être plus facile que vous ne le pensez.
          </h3>
          <p>
            Si vous débutez votre aventure en ligne, Elementor propose un
            hébergement à un prix très abordable, idéal pour ceux qui veulent
            démarrer rapidement sans se ruiner.
          </p>
          <Link
            target="_blank"
            className="footer-button projet-button m-global"
            to="https://be.elementor.com/visit/?bta=222415&nci=5566"
          >
            Elementor Hosting
          </Link>
        </div>
      </div>

      <div className="pv-3rem elementor-type">
        <Link
          target="_blank"
          className="techno-link"
          to="https://be.elementor.com/visit/?bta=222415&nci=5634"
        >
          <img src="../img/M-Affiliation/black_ele.png" alt={""} />
        </Link>
       <div>
         <h3>
           Mais peut-être avez-vous déjà votre propre hébergement et
           <span> </span>
           <span>
             vous souhaitez simplement intégrer les fonctionnalités incroyables
             d'Elementor à votre site WordPress existant.
           </span>
           <br />
           <br />
           Dans ce cas, vous pouvez opter pour le plugin Elementor Pro.
         </h3>
         <p>
           En utilisant le lien ci-dessous, vous bénéficierez d'une réduction
           spéciale qui vous permettra d'acquérir tous les outils professionnels
           dont vous avez besoin pour donner vie à votre vision web.
         </p>
         <Link
           target="_blank"
           className="footer-button projet-button m-global"
           to="https://be.elementor.com/visit/?bta=222415&nci=5634"
         >
           Elementor Pro
         </Link>
       </div>
      </div>
    </div>
  );
};

const Hostinger = () => {
  return (
    <div className="box-hostinger">
      <Link
        target="_blank"
        className="techno-link"
        to="https://hostinger.fr?REFERRALCODE=1CARLOSGIL01"
      >
        <img src="../img/M-Affiliation/hostinger-logo.png" alt={""} />
      </Link>

      <div>
        <h3>
          <span>
            Nous avons choisi Hostinger pour héberger ce projet , une décision qui
            reflète notre confiance en leur service de qualité et en leur
            fiabilité.
          </span>
          <br />
          <br />
          Chez Hostinger, nous recommandons notre service à tous nos clients et
          partenaires pour son excellence et sa performance à des prix accessibles
        </h3>
        <p>
          Et ce n'est pas tout : en suivant le lien ci-dessous, vous bénéficierez
          d'une réduction exclusive, uniquement disponible à travers ce lien
        </p>
        <Link
          target="_blank"
          className="footer-button projet-button m-global"
          to="https://hostinger.fr?REFERRALCODE=1CARLOSGIL01"
        >
          Hostinger
        </Link>
      </div>
    </div>
  );
};

const Qonto = () => {
  return (
    <div className="box-qonto">
      <Link target="_blank" to="https://qonto.com/r/rd98yl" className="pv-3rem techno-link">
        <img src="../img/M-Affiliation/qonto-logo.png" alt={""} />
      </Link>

      <div>
        <h3>
          <span>
            Si vous êtes sur le point de démarrer une activité, nous vous
            recommandons vivement Qonto. Intégrez-le avec Stripe pour faciliter
            les encaissements de vos clients.
          </span>
          <br />
          <br />
          Qonto offre un excellent service à un prix abordable pour la gestion des
          comptes de votre entreprise
        </h3>
        <p>
          "Chez Tres Interiors, nous utilisons Qonto et nous en sommes très
          satisfaits."
          <br /> En suivant ce lien, vous recevrez un bonus de bienvenue de 80€.
          Certaines conditions s'appliquent, mais nous vous recommandons Qonto les
          yeux fermés!
        </p>
        <Link
          target="_blank"
          className="footer-button projet-button m-global"
          to="https://qonto.com/r/rd98yl"
        >
          Ouvrir une compte
        </Link>
      </div>
    </div>
  );
};

export const MarketingAffiliation = ({ elementor, hostinger, qonto }) => {
  // Vérifier si à la fois elementor et hostinger sont vrais
  if (elementor && hostinger) {
    return (
      <>
        <Elementor />
        <Hostinger />
        <Qonto />
      </>
    );
  }
  // Vérifier si elementor est vrai mais pas hostinger
  if (elementor) {
    return (
      <>
        <Elementor />
        <Qonto />
      </>
    );
  }
  // Vérifier si hostinger est vrai mais pas elementor
  if (hostinger) {
    return (
      <>
        <Hostinger />
        <Qonto />
      </>
    );
  }

  // Vérifier si qonto est le seul element
  if (qonto) {
    return (
      <>
        <Qonto />
      </>
    );
  }
};
