/** @format */
import { FaBars, FaArrowLeft } from "react-icons/fa";
import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";

export const MenuBurger = () => {
  const [menuShow, setMenuShow] = useState(false);
  const [activeItem, setActiveItem] = useState(null);

  const handleMenuToggle = () => {
    setMenuShow(!menuShow);
  };

  const handleItemClick = (itemName) => {
    setActiveItem(itemName);
    setMenuShow(false); // Fermer le menu lorsque vous cliquez sur un élément de menu
  };

  const handleOutsideClick = (event) => {
    if (menuShow && !event.target.closest(".menu")) {
      setMenuShow(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [menuShow]);

  return (
    <div className="menu">
      <button className="menu-toggle" onClick={handleMenuToggle}>
        {menuShow ? (
          <FaArrowLeft className="icon-burger" icon="fa-light" />
        ) : (
          <FaBars className="icon-burger" icon="fa-light" />
        )}
      </button>
      {menuShow && (
        <div className="menu-items">
          <ul>
            <li>
              <NavLink
                to="/"
                className="btn-menu"
                onClick={() => handleItemClick("/")}
              >
                Accueil
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/a-propos"
                className="btn-menu"
                onClick={() => handleItemClick("a-propos")}
              >
                À Propos
              </NavLink>
            </li>

            <li>
              <NavLink
                to="/realisations"
                className="btn-menu"
                onClick={() => handleItemClick("realisations")}
              >
                Réalisations
              </NavLink>
            </li>
            {/* <li>
              <NavLink to="services" className="btn-menu">
                Services
              </NavLink>
            </li> */}
            <li>
              <NavLink
                to="/contact"
                className="btn-menu"
                onClick={() => handleItemClick("contact")}
              >
                Contact
              </NavLink>
            </li>
            {/* <li>
              <NavLink
                to="/blog"
                className="btn-menu"
                onClick={() => handleItemClick("blog")}
              >
                Blog
              </NavLink>
            </li> */}
          </ul>
        </div>
      )}
    </div>
  );
};
