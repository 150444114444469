/** @format */

import React from "react";
import { Helmet } from "react-helmet";

export const SeoComponent = ({
  titlePage,
  descPage,
  keyPage,
  ogTitle,
  ogDesc,
  ogUrl,
  ogImg,
  tCard,
  tTitle,
  tDesc,
  tImg,
  rev,
  createAt,
  imagePage,
  typePage,
  urlBase,
}) => {
  return (
    <Helmet defer>
      <html lang="fr" />
      <meta name="robots" content="index, follow" />
      <link rel="canonical" href={urlBase} />
      {/*  <!-- Balise base  --> */}
      <title>{titlePage}</title>
      <meta name="description" content={descPage} />
      <meta name="keywords" content={keyPage} />
      {/*    <!-- Balises OpenGraph  --> */}
      <meta property="og:title" content={ogTitle} />
      <meta property="og:description" content={ogDesc} />
      <meta property="og:url" content={ogUrl} />
      <meta property="og:image" content={ogImg} />
      {/*    <!-- Balises Twitter --> */}
      <meta name="twitter:card" content={tCard} />
      <meta name="twitter:title" content={tTitle} />
      <meta name="twitter:description" content={tDesc} />
      <meta name="twitter:image" content={tImg} />
      {/*    <!-- Version page --> */}
      <meta name="revision" content={rev} />
      {/*---------- Application/ld+json ---------- */}
      <script type="application/ld+json">
        {JSON.stringify({
          "@context": "http://schema.org",
          "@type": typePage,
          headline: titlePage,
          datePublished: createAt,
          image: imagePage,
          author: {
            "@type": "Person",
            name: "Carlos Gil Rivas",
          },
          publisher: {
            "@type": "Organization",
            name: "Divs Labs",
            logo: {
              "@type": "ImageObject",
              url: "https://divs.fr/logoDL.png",
            },
          },
          description: descPage,
        })}
      </script>
    </Helmet>
  );
};

// Modele à copier lors de l'appel de mon composant

//   titlePage=""
//   descPage=""
//   keyPage=""
//   ogTitle=""
//   ogDesc=""
//   ogUrl=""
//   ogImg=""
//   tCard=""
//   tTitle=""
//   tDesc=""
//   tImg=""
//   rev=""
//   createAt=""
//   imagePage=""
//   typePage=""
