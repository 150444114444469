/** @format */

import React from "react";
import SearchBar from "./SearchBar";

export const SidebarBlog = ({ backgroundColor, onSearch }) => {
  return (
    <div className="SideBarBlog" style={{ backgroundColor: backgroundColor }}>
      <div className="highlight-post">
        <h2>HOLA</h2>
        <p> ASDHIASHDSAHGFIKSDHJFGSDRJGLKSDJKHSDFKHDSKFHJSDKASDHJASDHSADHASD</p>
        <SearchBar onSearch={onSearch} />
      </div>
    </div>
  );
};
