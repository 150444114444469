/** @format */

import React from "react";

import { FaLinkedin, FaWhatsapp, FaDiscord, FaInstagram } from "react-icons/fa";
import { NavLink } from "react-router-dom";
import { SeoComponent } from "../components/SeoComponent";

export const QrContact = () => {
  // Créez une nouvelle instance de Date avec la date souhaitée
  const createDate = new Date("2024-03-11");
  // Obtenez la date au format ISOString pour l'utiliser dans votre JSON-LD
  const createAt = createDate.toISOString();
  return (
    <>
      <SeoComponent
        titlePage="Carlos Gil Rivas - Divs Labs"
        descPage="Contactez-nous dès aujourd'hui pour démarrer votre succès en ligne avec Divs Labs ! Nous sommes spécialisés dans la création et la refonte de sites web et d'applications sur mesure"
        keyPage={[
          "Création de sites web sur mesure",
          "Refonte de sites web",
          "Développement web",
          "Coaching en programmation",
          "Développement Symfony",
          "Développement React",
          "Développement frontend",
          "Développement backend",
          "PHP",
          "JavaScript",
          "HTML",
          "CSS",
          "Applications réactives",
          "Coaching Symfony",
          "Coaching React",
          "Portfolio web",
          "Développeur freelance",
          "Services web",
          "Expérience utilisateur (UX/UI)",
          "Responsive design",
          "SEO (Optimisation pour les moteurs de recherche)",
          "Mentorat en programmation",
          "Technologies web",
          "Conception de site internet",
          "Programmation web avancée",
        ]}
        ogTitle="Carlos Gil Rivas - Divs Labs"
        ogDesc="Créez votre site web professionnel et atteignez le succès en ligne dès aujourd'hui! RDV GRATUIT"
        ogUrl="https://divs.fr/QrContact/"
        ogImg="https://divs.fr/logoDL.png"
        tCard="https://divs.fr/logoDL.png"
        tTitle="Carlos Gil Rivas - Divs Labs"
        tDesc="Créez votre site web professionnel et atteignez le succès en ligne dès aujourd'hui! RDV GRATUIT"
        tImg="https://divs.fr/logoDL.png"
        rev="1.0"
        typePage="ContactPage"
        createAt={createAt}
        imagePage="https://divs.fr/img/machine.png"
        urlBase="https://divs.fr/QrContact/"
      />
      <div className="qrHeader">
        <img src="../img/cards/exp-uti.png" className="qrImg" alt="Test" />
        <h1>Carlos Gil Rivas - Divs Labs</h1>
        <h2> Développer Web et Mobile </h2>
        <div className="qrHeaderBas">
          <a href="tel:+33682669066" className="call-button">
            CALL
          </a>
          <a href="mailto:cgilvas@divs.fr" className="call-button">
            EMAIL
          </a>
        </div>
      </div>
      <div className="qrHeader qrContent">
        <div className="qrContentBas">
          <div>
            <p>Mobile</p>
            <h3>+33 6 82 66 90 66</h3>
          </div>
          <div>
            <p>Email</p>
            <h3>cgilvas@divs.fr</h3>
          </div>
          <div>
            <p>WebSite</p>
            <p className="bobo">Tu es deja sur mon web site </p>
            <h3>Divs.fr</h3>
          </div>
        </div>

        <h2>Social Medias</h2>
        <div className="socialBar">
          <NavLink target="_blank" to={"https://linkedin.com/in/divseidon"}>
            <FaLinkedin size={30} className="icons" />
          </NavLink>
          {/* <NavLink target="_blank" to={"https://github.com/Divseidon"}>
            <FaGithub size={30} className="icons" />
          </NavLink> */}
          <NavLink target="_blank" to={"https://www.instagram.com/divseidon/"}>
            <FaInstagram size={30} className="icons" />
          </NavLink>
          <NavLink target="_blank" to={"https://wa.me/+33682669066"}>
            <FaWhatsapp size={30} className="icons" />
          </NavLink>
          <NavLink target="_blank" to={"https://discord.gg/UgevntNb"}>
            <FaDiscord size={30} className="icons" />
          </NavLink>
        </div>
      </div>
    </>
  );
};
