/** @format */

import React from "react";
import {
  FaPhp,
  FaWordpress,
  FaHtml5,
  FaCss3,
  FaJs,
  FaReact,
  FaElementor,
  FaSymfony,
  FaProjectDiagram,
  FaGithub,
} from "react-icons/fa";

import { AiFillApi } from "react-icons/ai";
import { TbSeo } from "react-icons/tb";

const TechnologiesSection = ({ technos }) => {
  const getIconForTechnology = (technology) => {
    switch (technology) {
      case "Html":
        return <FaHtml5 size={30} className="icons" />;
      case "Css":
        return <FaCss3 size={30} className="icons" />;
      case "Js":
        return <FaJs size={30} className="icons" />;
      case "React":
        return <FaReact size={30} className="icons" />;
      case "Php":
        return <FaPhp size={30} className="icons" />;
      case "Wordpress":
        return <FaWordpress size={30} className="icons" />;
      case "Elementor":
        return <FaElementor size={30} className="icons" />;
      case "Api":
        return <AiFillApi size={30} className="icons" />;
      case "Symfony":
        return <FaSymfony size={30} className="icons" />;
      case "Seo":
        return <TbSeo size={30} className="icons" />;
      case "Projet":
        return <FaProjectDiagram size={30} className="icons" />;
      case "GitHub":
        return <FaGithub size={30} className="icons" />;
      default:
        return null;
    }
  };

  return <div className="technolBar">{technos.map(getIconForTechnology)}</div>;
};

export default TechnologiesSection;
