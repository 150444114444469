/** @format */

import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import ReactMarkdown from "react-markdown";
import { SidebarBlog } from "./SidebarBlog";
import { Helmet } from "react-helmet";


const CustomLink = ({ href, children }) => (
  <a href={href} target="_blank" rel="noopener noreferrer">
    {children}
  </a>
);

export const Post = ({ article }) => {
  const [post, setPost] = useState(null);
  const { slug } = useParams();
  const urlBase = process.env.REACT_APP_DIVS_BACKEND_URL;

  const apiUrl = `${urlBase}articles/`;
  console.log("Requesting API:", apiUrl);

  useEffect(() => {
    const fetchPost = async () => {
      try {
        const response = await axios.get(`${urlBase}articles/${slug}`);
        setPost(response.data);
        console.log(response.data);
      } catch (error) {
        console.error("Error fetching post:", error);
        setPost(false);
      }
    };

    fetchPost();
  }, [slug, urlBase]);

  if (!post) {
    return (
      <div className="loading-container">
        <div className="loading-point point-1"></div>;
        <div className="loading-point point-2"></div>;
        <div className="loading-point point-3"></div>;
      </div>
    );
  }

  return (
    <div className="content-post">
      <Helmet>
        {/* Title et Meta Description */}
        <title>{post.title} - Blog Divs</title>
        <meta
          name="description"
          content={post.metaDescription || "Bienvenue sur divs."}
        />

        {/* Open Graph */}
        <meta property="og:title" content={post.ogTitle} />
        <meta
          property="og:description"
          content={post.ogDescription || "Bienvenue sur divs."}
        />
        <meta property="og:image" content={urlBase + post.imageTwitterURL} />

        {/* Twitter Cards */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={post.twitterTitle} />
        <meta
          name="twitter:description"
          content={post.description_meta || "Bienvenue sur divs."}
        />
        <meta name="twitter:image" content={urlBase + post.imageTwitterURL} />

        {/* Canonical Tag */}
        <link rel="canonical" href={`${urlBase}/articles/${slug}`} />

        {/* Structured Data */}
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "http://schema.org",
            "@type": "BlogPosting",
            headline: post.title,
            datePublished: post.create_at,
            image: urlBase + post.imageBlogURL,
            author: {
              "@type": "Person",
              name: "Carlos Gilvas",
            },
            publisher: {
              "@type": "Organization",
              name: "Divs Creation et Coahing des sites webs",
              logo: {
                "@type": "ImageObject",
                url: "URL du logotype",
              },
            },
            description: post.metaDescription,
          })}
        </script>

        {/* Langue de la page */}
        <html lang="fr" />

        {/* Favicon */}
        {/* <link rel="icon" href="favicon.ico" type="image/x-icon" /> */}

        {/* Référencement des images */}
        <img
          src={urlBase + post.imageBlogURL}
          alt={post.imageAltText || "Texte alternatif"}
        />
        <img
          src={urlBase + post.imageTwitterURL}
          alt={post.twitterImageAltText || "Texte alternatif"}
        />
        <img
          src={urlBase + post.imagePostURL}
          alt={post.imageAltText || "Texte alternatif"}
        />

        {/* Statut de l'indexation */}
        <meta name="robots" content="index, follow" />
      </Helmet>
      <article className="post-article" key={post.id}>
        <h1>{post.title}</h1>
        <img
          className="post-img"
          src={urlBase + post.imageBlogURL}
          alt={post.imageAltText || "Innover"}
        />
        <ReactMarkdown components={{ a: CustomLink }}>
          {post.contenu}
        </ReactMarkdown>
        {/* Autres détails du post */}
      </article>
    </div>
  );
};
