/** @format */

import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Home } from "../pages/Home";
import { APropos } from "../pages/APropos";
import { Realisations } from "../pages/Realisations";
import { Services } from "../pages/Services";
import { Contact } from "../pages/Contact";
import { NavBar } from "../components/BarMenu/NavBar";
import { Blog } from "../pages/Blog";
import { Post } from "../components/blog/Post";
import { PostPortfolio } from "../components/realisations/PostPortfolio";
import { QrContact } from "../pages/QrContact";
import { MentiosLegales } from "../pages/MentiosLegales";
import SocialBar from "../components/BarMenu/SocialBar";

const MainComponent = () => {
  return (
    <>
      <NavBar />
      <div className="fixed-button">
        <SocialBar showWhatsapp showLinkedin showInstagram />
      </div>

      <div className="content">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="a-propos" element={<APropos />} />
          <Route path="realisations" element={<Realisations />} />
          <Route path="services" element={<Services />} />
          <Route path="QrContact" element={<QrContact />} />
          <Route path="contact" element={<Contact />} />
          <Route path="blog/*" element={<Blog />} />
          <Route path="blog/:slug" element={<Post />} />
          <Route path="blog/editer" element={<Blog />} />
          <Route path="realisations/:slug" element={<PostPortfolio />} />
          <Route
            path="mentions-legales-et-politique-de-confidentialite"
            element={<MentiosLegales />}
          />
        </Routes>
      </div>
    </>
  );
};

export const RoutePrincipale = () => {
  return (
    <Router>
      <MainComponent />
    </Router>
  );
};
