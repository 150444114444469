/** @format */

import React from "react";
import { FaWhatsapp } from "react-icons/fa";

import { Link } from "react-router-dom";

export const ContactMe = () => {
  return (
    <div className="contactMe">
      <div className="contactMe-text">
        <p>
          <span>Si vous avez aimé ce projet</span> et souhaitez discuter du
          vôtre, je suis disponible sur WhatsApp.
          <br />
          <br />
          <span>Je serai ravi de vous accompagner.</span>
        </p>
        <Link
          className=" icon-wme"
          target="_blank"
          to={"https://wa.me/33682669066"}
        >
          <FaWhatsapp fill="#333" size={30} className="icon-wme" />
        </Link>
      </div>
    </div>
  );
};
