/** @format */

import React, { useState, useEffect, useRef } from "react";

export const MachineText = ({ text }) => {
  const [displayText, setDisplayText] = useState("");
  const textRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            startTyping();
            observer.disconnect(); // Désactive l'observer une fois que le composant est dans la vue
          }
        });
      },
      {
        root: null,
        rootMargin: "0px",
        threshold: 0.5, // Change cette valeur selon quand tu veux déclencher l'effet
      }
    );

    if (textRef.current) {
      observer.observe(textRef.current);
    }

    return () => {
      if (textRef.current) {
        observer.unobserve(textRef.current);
      }
    };
  }, []);

  const startTyping = () => {
    let index = 0;
    const intervalId = setInterval(() => {
      if (index <= text.length) {
        setDisplayText((prevText) => prevText + (text[index] || "")); // Utilise text[index] ou une chaîne vide si text[index] est undefined
        index++;
      } else {
        clearInterval(intervalId);
      }
    }, 60); // Ajuste la vitesse de frappe ici
  };

  // Ajoute un saut de ligne après chaque point d'interrogation
  const displayWithLineBreaks = displayText.replace(/\?/g, "?\n");

  return (
    <span ref={textRef} style={{ whiteSpace: "pre-line" }}>
      {displayWithLineBreaks}
    </span>
  );
};
