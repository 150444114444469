/** @format */

import React, { useState, useEffect } from "react";

const Services = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Limpia el event listener cuando el componente se desmonta
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // Estados para controlar la visibilidad de cada componente
  const [mostrarDiv1, setMostrarDiv1] = useState(true);
  const [mostrarDiv2, setMostrarDiv2] = useState(false);
  const [mostrarDiv3, setMostrarDiv3] = useState(false);
  const [mostrarDiv4, setMostrarDiv4] = useState(false);

  const mostrarContenido = (div) => {
    // Función para mostrar el div seleccionado y ocultar los demás
    setMostrarDiv1(div === "div1");
    setMostrarDiv2(div === "div2");
    setMostrarDiv3(div === "div3");
    setMostrarDiv4(div === "div4");
  };

  return (
    <div className="services-container">
      <div className="marche">
        <h2 className="titre-works">
           Comment Ça Marche ?
        </h2>
      </div>
      <div className="service-buttons">
        <button
          onClick={() => mostrarContenido("div1")}
          className={mostrarDiv1 ? "activo" : ""}
        >
          {windowWidth > 666
            ? "Consultation Initiale "
            : "Consultation Initiale"}
        </button>
        <button
          onClick={() => mostrarContenido("div2")}
          className={mostrarDiv2 ? "activo" : ""}
        >
          {windowWidth > 666
            ? "Conception Personnalisée"
            : "Conception Personnalisée"}
        </button>
        <button
          onClick={() => mostrarContenido("div3")}
          className={mostrarDiv3 ? "activo" : ""}
        >
          {windowWidth > 666 ? "Développement Expert" : "Développement Expert"}
        </button>
        <button
          onClick={() => mostrarContenido("div4")}
          className={mostrarDiv4 ? "activo" : ""}
        >
          {windowWidth > 666 ? "Lancement Réussi" : "Lancement Réussi"}
        </button>
      </div>

      {mostrarDiv1 && (
        <div className="service-info">
          <div className="service-card">
            <h2>Comprendre vos objectifs et besoins</h2>
            <p>
              La première étape de notre collaboration commence par une
              consultation approfondie. Nous souhaitons comprendre en profondeur
              vos objectifs, vos valeurs et les besoins spécifiques de votre
              entreprise. <br />
              <br /> C'est le moment où nous établissons une communication
              ouverte pour discuter de vos attentes, de votre public cible, de
              vos concurrents, et de tout élément crucial pour la réussite de
              votre projet. <br /> <br />
              Cette compréhension approfondie nous guide tout au long du
              processus pour nous assurer que chaque aspect de votre site web
              est aligné sur vos objectifs commerciaux.
            </p>
            <button className="button-89">En savoir plus</button>
          </div>
          <img src="../img/home/meeting.jpg" className="img-service" alt="" />
        </div>
      )}

      {mostrarDiv2 && (
        <div className="service-info">
          <div className="service-card">
            <h2>Création d'un site qui reflète votre identité</h2>
            <p>
              Forts de la compréhension acquise lors de la consultation
              initiale, notre équipe de concepteurs talentueux se met au travail
              pour créer une conception web personnalisée. <br />
              <br /> Chaque élément, des couleurs au design, est soigneusement
              sélectionné pour refléter l'identité unique de votre entreprise.
              Nous ne nous contentons pas de créer un site attrayant
              visuellement, mais nous nous efforçons également de transmettre
              votre message et de créer une expérience qui résonne avec votre
              public cible. <br />
              <br /> La conception personnalisée assure que votre site se
              démarque et laisse une impression mémorable sur les visiteurs.
            </p>

            <button className="button-89">En savoir plus</button>
          </div>
          <img
            src="../img/home/conception.webp"
            className="img-service"
            alt=""
          />
        </div>
      )}

      {mostrarDiv3 && (
        <div className="service-info">
          <div className="service-card">
            <h2>
              Utilisation des dernières technologies pour des performances
              optimales
            </h2>
            <p>
              Notre équipe de développeurs chevronnés entre en action pour
              donner vie à la conception. <br />
              <br /> Nous utilisons les dernières technologies, y compris des
              frameworks comme React et d'autres outils de pointe, pour assurer
              des performances optimales et une expérience utilisateur fluide.
              Chaque ligne de code est écrite avec précision pour garantir la
              stabilité, la sécurité et la réactivité de votre site. <br />
              <br /> Nous sommes engagés à rester à jour avec les avancées
              technologiques pour offrir à votre site web un avantage compétitif
              sur le plan technique.
            </p>

            <button className="button-89">En savoir plus</button>
          </div>
          <img src="../img/home/react.jpg" className="img-service" alt="" />
        </div>
      )}
      {mostrarDiv4 && (
        <div className="service-info">
          <div className="service-card">
            <h2>Votre site web prêt à impressionner et à performer.</h2>
            <p>
              Une fois le développement terminé, nous procédons à une série de
              tests rigoureux pour garantir que chaque fonctionnalité fonctionne
              parfaitement. <br />
              <br /> Une fois que nous sommes certains que votre site répond à
              nos normes élevées, nous planifions le lancement. <br />
              <br /> Notre équipe s'assure que tout est en place, depuis
              l'hébergement jusqu'à la configuration finale. Le résultat final
              est un site web prêt à impressionner et à performer, offrant une
              expérience utilisateur exceptionnelle et répondant à tous les
              objectifs définis au départ.
            </p>

            <button className="button-89">En savoir plus</button>
          </div>
          <img src="../img/home/reu.png" className="img-service" alt="" />
        </div>
      )}
    </div>
  );
};

export default Services;
