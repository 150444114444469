/** @format */

import React, { useRef } from "react";

import Services from "../components/home/services";

import { Footer } from "../components/BarMenu/Footer";

import { CardHome } from "../components/CardHome";
import { SeoComponent } from "../components/SeoComponent";
import Cube from "../components/Cube";

import { ScrollArrows } from "../components/ScrollArrows";

export const Home = () => {
  const section1Ref = useRef(null);
  const section2Ref = useRef(null);
  const section3Ref = useRef(null);
  const section4Ref = useRef(null);
  const section5Ref = useRef(null);

  const scrollToSection = (sectionRef) => {
    if (sectionRef.current) {
      sectionRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  // Créez une nouvelle instance de Date avec la date souhaitée
  const createDate = new Date("2024-03-11");
  // Obtenez la date au format ISOString pour l'utiliser dans votre JSON-LD
  const createAt = createDate.toISOString();
  return (
    <>
      <SeoComponent
        titlePage="Création des sites Web et App"
        descPage="Création et refonte de sites web et apps sur mesure. Démarrez votre activité en ligne"
        keyPage={[
          "Création de sites web sur mesure",
          "Refonte de sites web",
          "Développement web",
          "Coaching en programmation",
          "Développement Symfony",
          "Développement React",
          "Développement frontend",
          "Développement backend",
          "PHP",
          "JavaScript",
          "HTML",
          "CSS",
          "Applications réactives",
          "Coaching Symfony",
          "Coaching React",
          "Portfolio web",
          "Développeur freelance",
          "Services web",
          "Expérience utilisateur (UX/UI)",
          "Responsive design",
          "SEO (Optimisation pour les moteurs de recherche)",
          "Mentorat en programmation",
          "Technologies web",
          "Conception de site internet",
          "Programmation web avancée",
        ]}
        ogTitle="Créez votre site web avec un expert"
        ogDesc="Créez votre site web professionnel et atteignez le succès en ligne dès aujourd'hui! RDV GRATUIT"
        ogUrl="https://divs.fr/"
        ogImg="https://divs.fr/logoDL.png"
        tCard="https://divs.fr/logoDL.png"
        tTitle="Créez votre site web avec un expert"
        tDesc="Créez votre site web professionnel et atteignez le succès en ligne dès aujourd'hui! RDV GRATUIT"
        tImg="https://divs.fr/logoDL.png"
        rev="1.0"
        typePage="Service"
        createAt={createAt}
        imagePage="https://divs.fr/img/machine.png"
        urlBase="https://divs.fr/"
      />

      <header ref={section1Ref} id="section1" className="scroll-snap">
        <div className="w-100">
          <h1 id="titre-home" className="titre-home">
            <span> Sites web et apps sur mesure </span> <br />
            Sublimez votre identité <br />{" "}
          </h1>
        </div>
        <div className=" c-box">
          <div className="box-Text">
            <h2 className="avant-titre">
              Vous recherchez une solution adaptée pour votre projet ?
            </h2>

            <p className="avant-titre">
              À Paris, une start-up nommée Divs Labs aidait les entreprises à
              créer des sites web exceptionnels. Anaëlle, une entrepreneure de
              bijoux faits à la main, fit appel à eux. Après des semaines de
              travail acharné, son nouveau site web attira de nombreux clients,
              propulsant son entreprise vers le succès. <br />
            </p>

            <p className="avant-titre p-action">
              Qu'attendez-vous pour nous contacter dès maintenant et parler de
              votre projet ?
            </p>
            {/*<SocialBar showLinkedin showWhatsapp showInstagram />*/}
          </div>
          <div className="box-cube">
            <Cube
              imgFront={"../img/cube/idea1.webp"}
              textFront="Il vous faut simplement une belle idée, un rêve et de la motivation."
              imgLeft={"../img/cube/hands1.webp"}
              textLeft="Votre idée entre nos mains, elle devient réalité grâce à notre savoir-faire..."
              imgRight={"../img/cube/born1.webp"}
              textRight=" Ensemble, développons une marque qui aura une influence positive à travers le monde."
              imgBack={"../img/cube/futurelove.webp"}
              textBack="Prêt(e) à concrétiser votre vision? Prenez contact avec nous dès maintenant pour une consultation gratuite.
              "
            />
            {/* <p>
              Ces images ont été générées par une intelligence artificielle.
              <br />
              C'est vraiment impressionnant, n'est-ce pas ?
            </p> */}
          </div>
        </div>
        <ScrollArrows
          direction="down"
          optionalClass="headerArrow"
          onClick={() => scrollToSection(section2Ref)}
        />
      </header>

      {/* ---------------------Services Card----------------------- */}

      <section
        ref={section2Ref}
        id="section2"
        className=" services-card scroll-snap"
      >
        <ScrollArrows
          direction="up"
          onClick={() => scrollToSection(section1Ref)}
        />
        <ScrollArrows
          direction="down"
          onClick={() => scrollToSection(section3Ref)}
        />
        <CardHome />
      </section>

      <section ref={section3Ref} id="section3" className="about scroll-snap">
        <ScrollArrows
          direction="up"
          onClick={() => scrollToSection(section2Ref)}
        />
        <ScrollArrows
          direction="down"
          onClick={() => scrollToSection(section4Ref)}
        />
        <div className="w-100 f-col">
          <h2 className="titre-about">Pourquoi Nous Choisir ?</h2>
          <div className="banner-about">
            <div className="banner-about-content">
              <h3 className="sub-titres-about">
                Approche Personnalisée pour Chaque Projet :
              </h3>
              <p>
                Nous comprenons que chaque entreprise est unique, et c'est
                pourquoi nous adoptons une approche personnalisée pour chaque
                projet. Avant de commencer, nous prenons le temps de comprendre
                vos objectifs, vos valeurs et l'identité de votre marque. Cette
                approche nous permet de créer des solutions sur mesure qui
                répondent spécifiquement à vos besoins. Nous croyons en la
                puissance de la personnalisation pour assurer la pertinence et
                l'efficacité maximale de chaque site web que nous développons.
              </p>
              <h3 className="sub-titres-about">
                Respect des Délais et Budgets :
              </h3>
              <p>
                Nous comprenons l'importance du temps et des ressources
                financières dans tout projet. C'est pourquoi nous nous engageons
                à respecter les délais convenus et les budgets définis. Notre
                processus de gestion de projet est rigoureux, garantissant une
                planification précise, une communication transparente et une
                exécution efficace. Vous pouvez avoir confiance en notre
                capacité à livrer des résultats de qualité dans les délais
                impartis, sans dépasser le budget convenu
              </p>
            </div>
            <div className="banner-about-content">
              <h3 className="sub-titres-about">
                Expertise Technique de Pointe :
              </h3>
              <p>
                Notre équipe possède une expertise technique de pointe dans le
                domaine de la conception et du développement web. Nous restons
                constamment à la pointe des dernières tendances et technologies
                pour offrir à nos clients des solutions innovantes et
                performantes. Que ce soit dans l'utilisation de frameworks
                modernes comme React, la gestion de bases de données complexes
                ou la mise en œuvre de fonctionnalités avancées, notre expertise
                technique garantit la qualité et la robustesse de chaque projet.
              </p>
              <h3 className="sub-titres-about">
                Engagement envers l'Excellence :
              </h3>
              <p>
                Chez Divs Labs, nous sommes engagés envers l'excellence à chaque
                étape du processus. Nous n'aspirons pas simplement à répondre
                aux attentes, mais à les dépasser. Chaque ligne de code, chaque
                élément de conception, et chaque interaction utilisateur sont
                soigneusement examinés pour garantir une qualité exceptionnelle.
                Notre engagement envers l'excellence se reflète dans chaque
                projet que nous entreprenons, assurant ainsi la satisfaction
                totale de nos clients.
              </p>
            </div>
          </div>
          <div>
            <a className="button-89" href="a-propos">
              Demander un devis
            </a>
          </div>
        </div>
      </section>

      <section
        ref={section4Ref}
        id="section4"
        className="about-services scroll-snap"
      >
        <ScrollArrows
          direction="up"
          onClick={() => scrollToSection(section3Ref)}
        />
        <ScrollArrows
          direction="down"
          onClick={() => scrollToSection(section5Ref)}
        />
        <div className="w-100">
          <Services />
        </div>
      </section>

      <section ref={section5Ref} id="section5" className="scroll-snap">
        <ScrollArrows
          direction="up"
          onClick={() => scrollToSection(section4Ref)}
        />
        {/*<div className="footer-max ">
          <div>
           <p>Instagram </p>
          </div>

          <div>
            <p>Blog</p>
          </div> 
        </div>*/}
        <Footer />
        <ScrollArrows
          direction="up"
          onClick={() => scrollToSection(section1Ref)}
        />
      </section>

      {/* <Popup /> */}
    </>
  );
};
