/** @format */

import { useState } from "react";
import axios from "axios";
import OptionsProjet from "../components/OptionsProjet";
import DescriptionProjet from "../components/DescriptionProjet";
import { NavLink } from "react-router-dom";
import { SeoComponent } from "../components/SeoComponent";

export const Contact = () => {
  const [filledSteps, setFilledSteps] = useState(new Set());
  const [step, setStep] = useState(0);
  const [showNavigation, setShowNavigation] = useState(false); // Nuevo estado para controlar la visibilidad de la navegación
  const [formData, setFormData] = useState({
    nomPrenom: "",
    choixService: "",
    email: "",
    descriptionProjet: "",
  });

  const handleNextStep = () => {
    if (isStepFilled(step)) {
      setStep(step + 1);
      setShowNavigation(true);
    }
  };

  const handlePrevStep = () => {
    // Retroceder solo si no estamos en la primera etapa
    if (step > 0) {
      setStep(step - 1);
    }
  };
  const isStepFilled = (stepNumber) => {
    // Verificar si la etapa está llena basándose en los datos del formulario
    switch (stepNumber) {
      case 0:
        // La primera etapa siempre se considera llena
        return true;
      case 1:
        return formData.nomPrenom.trim() !== "";
      case 2:
        return formData.email.trim() !== "";
      case 3:
        return selectedOption !== null;
      case 4:
        return (
          formData.choixService && formData.descriptionProjet.trim() !== ""
        );
      default:
        return true;
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });

    // Actualizar el estado de las etapas llenas
    const updatedFilledSteps = new Set(filledSteps);
    if (value.trim() !== "") {
      updatedFilledSteps.add(step);
    } else {
      updatedFilledSteps.delete(step);
    }
    setFilledSteps(updatedFilledSteps);
  };

  const handleOptionClick = (option) => {
    setFormData({ ...formData, choixService: option });
    setSelectedOption(option);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formDataToSend = {
      nomPrenom: formData.nomPrenom,
      choixService: formData.choixService,
      email: formData.email,
      descriptionProjet: formData.descriptionProjet,
    };

    try {
      const response = await axios.post("http://api-rest.divs.fr/contact", {
        email: formData.email,
        nomPrenom: formData.nomPrenom,
        choixService: formData.choixService,
        descriptionProjet: formData.descriptionProjet,
      });

      console.log("Réponse du serveur :", response.data);
    } catch (error) {
      console.error("Erreur lors de l'envoi du formulaire :", error);
    }

    console.log("Formulaire soumis :", formDataToSend);
    setStep(step + 1); // Passage à l'étape suivante après soumission
  };

  const [selectedOption, setSelectedOption] = useState(null);

  const renderStepButtons = () => {
    const steps = ["Étape 1", "Étape 2", "Étape 3", "Étape 4", "Étape 5"];

    return steps.map((stepTitle, index) => (
      <button key={index} disabled={index !== step || !step}></button>
    ));
  };

  const renderStep = () => {
    switch (step) {
      case 0:
        return (
          <>
            <h1>Commencez votre succès en ligne avec Divs Labs !</h1>
            <p>
              Salut ! Notre formulaire est conçu pour vraiment te comprendre et
              répondre à tes besoins. <br />
              <br />
              Attends-toi à un accueil personnalisé et une prise de contact
              attentive pour que tu te sentes bien accompagné !
            </p>
            <button className="allons-y" onClick={handleNextStep}>
              Allons-y !
            </button>
          </>
        );
      case 1:
        return (
          <>
            <h2>Faisons connaissance !</h2>
            <p>
              Bonjour ! Je m'appelle Carlos. <br />
              <br />
              Et vous, comment vous appelez-vous ou quel est le nom de votre
              entreprise ?
            </p>

            <input
              type="text"
              name="nomPrenom"
              value={formData.nomPrenom}
              onChange={handleInputChange}
              placeholder="Prénom ou Entreprise"
            />
            {showNavigation && (
              <div className="navigation-buttons">
                <button onClick={handlePrevStep} disabled={step === 0}>
                  Précédent
                </button>
                <button onClick={handleNextStep} disabled={!isStepFilled(step)}>
                  Suivant
                </button>
              </div>
            )}
            <div className="step-btns">{renderStepButtons()}</div>
          </>
        );
      case 2:
        return (
          <>
            <p>
              <strong>{formData.nomPrenom}</strong> !<br /> <br />
              Nous sommes ravis de faire votre connaissance, <br />
              <br /> Nous avons besoin votre adresse e-mail :
            </p>
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleInputChange}
              placeholder="Votre E-mail"
            />
            {showNavigation && (
              <div className="navigation-buttons">
                <button onClick={handlePrevStep} disabled={step === 0}>
                  Précédent
                </button>
                <button onClick={handleNextStep} disabled={!isStepFilled(step)}>
                  Suivant
                </button>
              </div>
            )}
            <div className="step-btns">{renderStepButtons()}</div>
          </>
        );
      case 3:
        return (
          <>
            <OptionsProjet
              selectedOption={selectedOption}
              handleOptionClick={handleOptionClick}
            />
            {showNavigation && (
              <div className="navigation-buttons">
                <button onClick={handlePrevStep} disabled={step === 0}>
                  Précédent
                </button>
                <button onClick={handleNextStep} disabled={!isStepFilled(step)}>
                  Suivant
                </button>
              </div>
            )}
            {/* <button onClick={() => console.log(formData)}>
              Afficher dans la console
            </button> */}
            <div className="step-btns">{renderStepButtons()}</div>
          </>
        );
      case 4:
        return (
          <>
            <DescriptionProjet
              formData={formData}
              handleInputChange={handleInputChange}
              handlePrevStep={handlePrevStep}
              handleSubmit={handleSubmit}
              step={step}
              showNavigation={showNavigation}
            />

            <div className="step-btns">{renderStepButtons()}</div>
          </>
        );
      case 5:
        return (
          <>
            <h3> Merci pour ta confiance ! 🚀</h3>
            <p>
              Nous avons bien reçu ta demande et nous la traitons avec soin. Tu
              recevras bientôt un email de confirmation avec un résumé de ta
              demande.
              <br />
              <br />
              En attendant, n'hésite pas à explorer notre blog pour découvrir
              des conseils et astuces pour booster ton projet !
            </p>
            <NavLink
              className="footer-button"
              onClick={() =>
                (window.location.href = "https://www.divs.fr/blog")
              }
            >
              Visiter le blog
            </NavLink>
          </>
        );
      default:
        return null;
    }
  };
  // Créez une nouvelle instance de Date avec la date souhaitée
  const createDate = new Date("2024-03-11");
  // Obtenez la date au format ISOString pour l'utiliser dans votre JSON-LD
  const createAt = createDate.toISOString();
  return (
    <div className="contact-form">
      <SeoComponent
        titlePage="Commencez votre succès en ligne avec Divs Labs !"
        descPage="Contactez-nous dès aujourd'hui pour démarrer votre succès en ligne avec Divs Labs ! Nous sommes spécialisés dans la création et la refonte de sites web et d'applications sur mesure"
        keyPage={[
          "Création de sites web sur mesure",
          "Refonte de sites web",
          "Développement web",
          "Coaching en programmation",
          "Développement Symfony",
          "Développement React",
          "Développement frontend",
          "Développement backend",
          "PHP",
          "JavaScript",
          "HTML",
          "CSS",
          "Applications réactives",
          "Coaching Symfony",
          "Coaching React",
          "Portfolio web",
          "Développeur freelance",
          "Services web",
          "Expérience utilisateur (UX/UI)",
          "Responsive design",
          "SEO (Optimisation pour les moteurs de recherche)",
          "Mentorat en programmation",
          "Technologies web",
          "Conception de site internet",
          "Programmation web avancée",
        ]}
        ogTitle="Commencez votre succès en ligne avec Divs Labs !"
        ogDesc="Créez votre site web professionnel et atteignez le succès en ligne dès aujourd'hui! RDV GRATUIT"
        ogUrl="https://divs.fr/contact/"
        ogImg="https://divs.fr/logoDL.png"
        tCard="https://divs.fr/logoDL.png"
        tTitle="Commencez votre succès en ligne avec Divs Labs !"
        tDesc="Créez votre site web professionnel et atteignez le succès en ligne dès aujourd'hui! RDV GRATUIT"
        tImg="https://divs.fr/logoDL.png"
        rev="1.0"
        typePage="ContactPage"
        createAt={createAt}
        imagePage="https://divs.fr/img/machine.png"
        urlBase="https://divs.fr/contact/"
      />

      {renderStep()}
    </div>
  );
};
