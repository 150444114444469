/** @format */

import React from "react";
import { Footer } from "../components/BarMenu/Footer";
import { ScheduleButton } from "../components/ButtonGoogle";
import { SeoComponent } from "../components/SeoComponent";

export const APropos = () => {
  // Créez une nouvelle instance de Date avec la date souhaitée
  const createDate = new Date("2024-03-11");
  // Obtenez la date au format ISOString pour l'utiliser dans votre JSON-LD
  const createAt = createDate.toISOString();
  return (
    <>
      <div className="about-content">
        <SeoComponent
          titlePage="Divs Labs - Notre Vision"
          descPage="Créez votre présence en ligne avec confiance. Démarrez votre activité en faisant confiance à un expert de Divs Labs."
          keyPage={[
            "Création de sites web sur mesure",
            "Refonte de sites web",
            "Développement web",
            "Coaching en programmation",
            "Développement Symfony",
            "Développement React",
            "Développement frontend",
            "Développement backend",
            "PHP",
            "JavaScript",
            "HTML",
            "CSS",
            "Applications réactives",
            "Coaching Symfony",
            "Coaching React",
            "Portfolio web",
            "Développeur freelance",
            "Services web",
            "Expérience utilisateur (UX/UI)",
            "Responsive design",
            "SEO (Optimisation pour les moteurs de recherche)",
            "Mentorat en programmation",
            "Technologies web",
            "Conception de site internet",
            "Programmation web avancée",
          ]}
          ogTitle="Divs Labs - Notre Vision"
          ogDesc="Créez votre présence en ligne avec confiance. Démarrez votre activité en faisant confiance à un expert de Divs Labs."
          ogUrl="https://divs.fr/notre-vision"
          ogImg="https://divs.fr/logoDL.png"
          tCard="https://divs.fr/logoDL.png"
          tTitle="Divs Labs - Notre Vision"
          tDesc="Créez votre présence en ligne avec confiance. Démarrez votre activité en faisant confiance à un expert de Divs Labs."
          tImg="https://divs.fr/logoDL.png"
          rev="1.0.0"
          typePage="Organization"
          createAt={createAt}
          imagePage="https://divs.fr/img/machine.png"
          urlBase="https://divs.fr/notre-vision"
        />
        <div className="intitule">
          <h1>Rêvé par des humains, réalisé par des robots.</h1>
          <p>
            Notre objectif chez Divs est de transformer les rêves en réalité.
            Nous ne sommes pas seulement une marque, mais plutôt une vision qui
            découle de l'idée que chaque "div" est une partie de notre vaste
            univers, offrant une multitude de possibilités. <br />
            Le fruit concret de cette vision est notre laboratoire, un lieu où
            l'innovation et la création se mélangent pour façonner l'avenir.
          </p>
          <p>
            Nous expérimentons, façonnons et donnons vie à des idées audacieuses
            qui transcendent les limites de l'imagination dans cet espace dédié
            à la créativité.
            <br />
            Nous sommes fiers d'offrir au monde le meilleur de notre univers, où
            la magie de la technologie donne vie aux rêves.
          </p>
          {/* <img className="img-founder" alt="" src="../img/photo_about.png" /> */}
        </div>

        <div className="about-dev">
          <div className="left-side">
            <div className="box-info-about">
              <h2>L'âme créative au cœur de Divs Labs </h2>
              <p>
                Salut ! Je m'appelle Carlos et je suis le cerveau derrière cette
                agence, cette famille, ce groupe dédié à la création de sites
                web...
                <br />
                <br />
                Pour être honnête, je suis un passionné de technologie. Je suis
                constamment émerveillé par le rythme effréné et la rapidité avec
                laquelle les choses évoluent de nos jours. Je suis un rêveur,
                profondément intrigué par ce qui nous attend, notamment la
                croissance fulgurante de la technologie et son impact sur nos
                vies. /
                <br />
                <br />
                J'ai suivi des études en développement web. Certes, le chemin
                n'a pas été facile, j'ai rencontré de nombreux obstacles, mais
                aujourd'hui, je suis fier de présenter cette idée audacieuse
                qu'est Divs Labs.
                <br />
                <br />
                Le concept de Divs Labs est né d'une simple Div. Si vous cliquez
                sur le lien, vous trouverez une définition sommaire de ce qu'est
                une Div. Nous croyons en la capacité de créer de petits univers
                à l'intérieur d'une Div, que ce soit sur le web ou dans la vie
                réelle. Nous considérons notre entreprise comme un laboratoire
                où nous expérimentons, où nous travaillons sans relâche pour
                donner vie à vos projets au sein de ces Divs ! N'est-ce pas
                excitant?
                <br />
                <br />
                Je maîtrise les langages PHP, JavaScript, et j'aspire à
                continuer à apprendre d'autres langages. Bien sûr, je suis
                familier avec nos chers amis HTML et CSS, ainsi qu'avec des
                frameworks tels que Symfony, React ou ReactNative, parmi
                d'autres compétences. Chaque jour, je m'efforce de m'améliorer
                davantage.
              </p>
            </div>
            <div className="box-info-mision">
              <h2>Notre Mission</h2>
              <p>
                Bienvenue chez Divs Labs, où notre mission aujourd'hui est de
                réaliser vos idées les plus audacieuses. Nous sommes là pour
                vous accompagner dans votre parcours, que vous soyez un
                entrepreneur ambitieux, un aspirant créateur ou même si vous
                cherchez à propulser votre entreprise existante vers de nouveaux
                sommets.
                <br />
                <br />
                Divs Labs croit en la croissance collaborative. Nous voulons
                grandir avec vous en apportant transparence, enthousiasme et un
                travail presque parfait à chaque projet que nous entreprenons.
                Notre équipe est motivée à produire des résultats exceptionnels
                dont nous pouvons être fiers de dire : "Cela a été fabriqué chez
                Divs Labs!"
                <br />
                <br />
                Nous avons une passion pour les défis. N'hésitez donc pas à nous
                contacter. Ensemble, relevons le défi.
              </p>
            </div>
          </div>
          <div className="right-side"></div>
          <div className="box-section">
            <div className="box-info-engagement">
              <h2>Notre Engagement</h2>
              <p>
                Notre volonté va bien au-delà de la simple prestation de
                services. Nous sommes déterminés à trouver la meilleure solution
                pour chaque client, à créer des rendus élégants et beaux qui
                dépassent les attentes, et à traiter chaque projet avec le plus
                grand soin.
                <br />
                <br />
                Nous croyons fermement en l'inclusion et en l'égalité des
                chances. Chez nous, aucun client n'est discriminé en raison de
                son parcours ou de ses origines. Ce qui compte pour nous, c'est
                la motivation, le désir de réussir et la bienveillance.
                <br />
                <br />
                Notre engagement est dirigé envers ces personnes qui aspirent au
                succès, que ce soit dans leur vie professionnelle ou
                personnelle. Nous sommes là pour les accompagner, les soutenir
                et les guider sur le chemin de la réussite.
              </p>
            </div>
          </div>

          {/* <div className="footer-about">
            <p>
              Je propose des RDV gratuits sur google Meets, de 30min que vous
              aies besoin d'aide{" "}
            </p>
            <ScheduleButton />
          </div> */}
        </div>
        <Footer />
      </div>
    </>
  );
};
