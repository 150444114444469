/** @format */

import React from "react";
import { NavLink, Link } from "react-router-dom";
import SocialBar from "./SocialBar";
import { MachineText } from "./MachineText";

export const Footer = () => {
  const actionCall = `Prrêt à démarquer votre présence en Ligne ? Contactez-nous dès aujourd'hui pour un premier échange et laissez-nous 
  construire ensemble l'avenir numérique de votre entreprise `;

  return (
    <footer>
      <div className="call-action">
        <h2>
          <MachineText text={actionCall} />
        </h2>

        <Link className="footer-button" to="/contact">
          Connectons
        </Link>
      </div>
      <div className="footer-box">
        <div className="div30">
          <NavLink to="/mentions-legales-et-politique-de-confidentialite">
            Mentions Legales <br /> & Politique de confidentialité{" "}
          </NavLink>
        </div>

        <div className="div30">
          <p>Web site dedié au développement web</p>
          <p>Divs Labs Copyright - Tous les droits résérves 2024</p>
        </div>

        <div className="div30">
          <SocialBar showWhatsapp showLinkedin showGithub />
        </div>
      </div>
    </footer>
  );
};
