/** @format */

import React from "react";
import { Link } from "react-router-dom";

const Cube = ({
  imgFront,
  textFront,
  imgBack,
  textBack,
  imgRight,
  textRight,
  imgLeft,
  textLeft,
}) => {
  return (
    <>
      <div className="cube">
        <div
          className="face front  "
          style={{ backgroundImage: `url(${imgFront})` }}
        >
          <p>{textFront}</p>
        </div>
        <div
          className="face left"
          style={{ backgroundImage: `url(${imgLeft})` }}
        >
          <p>{textLeft}</p>
        </div>
        <div
          className="face back"
          style={{ backgroundImage: `url(${imgRight})` }}
        >
          <p>{textRight}</p>
        </div>
        <div
          className="face right"
          style={{ backgroundImage: `url(${imgBack})` }}
        >
          <p>{textBack}</p>
          <Link className="button-89" to="/contact">
            Contactez-nous
          </Link>
        </div>
      </div>
    </>
  );
};

export default Cube;
